import { PresetSize } from '../types';

export const defaultPresetSizes: PresetSize[] = [
  {
    id: 'tshirt',
    name: 'T-Shirt',
    width: 2875,
    height: 3900,
    selected: false
  },
  {
    id: 'sticker',
    name: 'Sticker',
    width: 2800,
    height: 2800,
    selected: false
  },
  {
    id: 'phonecase',
    name: 'Phone Case',
    width: 1187,
    height: 1852,
    selected: false
  },
  {
    id: 'wallart',
    name: 'Wall Art',
    width: 3840,
    height: 3840,
    selected: false
  },
  {
    id: 'pillow',
    name: 'Throw Pillow',
    width: 3225,
    height: 3225,
    selected: false
  },
  {
    id: 'totebag',
    name: 'Tote Bag',
    width: 2400,
    height: 3200,
    selected: false
  },
  {
    id: 'poster',
    name: 'Poster',
    width: 4500,
    height: 6000,
    selected: false
  }
];