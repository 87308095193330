import React from 'react';
import { Upload, Search, Image as ImageIcon } from 'lucide-react';

interface ActionButtonsProps {
  onImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchTextures: () => void;
  onPatternUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  onImageUpload,
  onSearchTextures,
  onPatternUpload
}) => {
  return (
    <div className="flex flex-col sm:flex-row justify-center gap-4">
      <label className="flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-br from-green-500 to-green-600 text-white rounded-lg hover:from-green-600 hover:to-green-700 cursor-pointer transition-all shadow-lg shadow-green-500/20 hover:shadow-green-500/30 text-center">
        <Upload size={20} />
        Upload Your Design
        <input type="file" accept="image/*" onChange={onImageUpload} className="hidden" />
      </label>

      <button
        onClick={onSearchTextures}
        className="flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-br from-purple-500 to-purple-600 text-white rounded-lg hover:from-purple-600 hover:to-purple-700 transition-all shadow-lg shadow-purple-500/20 hover:shadow-purple-500/30"
      >
        <Search size={20} />
        Get Textures
      </button>

      <label className="flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-br from-blue-500 to-blue-600 text-white rounded-lg hover:from-blue-600 hover:to-blue-700 cursor-pointer transition-all shadow-lg shadow-blue-500/20 hover:shadow-blue-500/30 text-center">
        <ImageIcon size={20} />
        Upload Your Texture
        <input type="file" accept="image/*" onChange={onPatternUpload} className="hidden" />
      </label>
    </div>
  );
};

export default ActionButtons;