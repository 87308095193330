import React, { useRef, useState, useCallback } from 'react';
import { DistressPattern, PresetSize, Dimensions } from '../types';
import { defaultPresetSizes } from '../data/presetSizes';
import { defaultPatterns } from '../data/defaultPatterns';
import SizeSelector from './SizeSelector';
import PatternSelector from './PatternSelector';
import Instructions from './Instructions';
import Header from './Header';
import ActionButtons from './DesignControls/ActionButtons';
import PreviewCanvas from './DesignPreview/PreviewCanvas';
import ResultActions from './DesignControls/ResultActions';
import AdPlaceholder from './Ads/AdPlaceholder';
import { renderPreview, processFinalOutput } from '../utils/canvasUtils';
import { convertToTransparentPNG } from '../utils/imageConversion';

const ImageProcessor = () => {
  const [image, setImage] = useState<string | null>(null);
  const [originalFilename, setOriginalFilename] = useState<string>('');
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: defaultPresetSizes[0].width,
    height: defaultPresetSizes[0].height
  });
  const [aspectLocked, setAspectLocked] = useState(true);
  const [presetSizes] = useState<PresetSize[]>(defaultPresetSizes);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const displayCanvasRef = useRef<HTMLCanvasElement>(null);
  const [originalImage, setOriginalImage] = useState<HTMLImageElement | null>(null);
  const [patterns, setPatterns] = useState<DistressPattern[]>(defaultPatterns);
  const [showInstructions, setShowInstructions] = useState(false);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && canvasRef.current && displayCanvasRef.current) {
      const filename = file.name.replace(/\.[^/.]+$/, "");
      setOriginalFilename(filename);

      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          setImage(event.target?.result as string);
          setOriginalImage(img);
          renderPreview(img, displayCanvasRef.current!, patterns);
        };
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePatternUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const transparentPattern = await convertToTransparentPNG(event.target?.result as string);
        
        const newPattern: DistressPattern = {
          id: Date.now().toString(),
          name: file.name,
          imageData: transparentPattern,
          selected: false,
          inverted: false
        };
        
        setPatterns(prevPatterns => [...prevPatterns, newPattern]);
        
        if (originalImage && displayCanvasRef.current) {
          renderPreview(originalImage, displayCanvasRef.current!, [...patterns, newPattern]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = useCallback(async () => {
    if (canvasRef.current && originalImage) {
      await processFinalOutput(originalImage, canvasRef.current, patterns, dimensions);
      const link = document.createElement('a');
      const filename = originalFilename ? `${originalFilename}-Distressed.png` : 'Distressed-Design.png';
      link.download = filename;
      link.href = canvasRef.current.toDataURL('image/png');
      link.click();
    }
  }, [originalImage, patterns, dimensions, originalFilename]);

  const handleSizeSelect = (size: PresetSize) => {
    setDimensions({ width: size.width, height: size.height });
  };

  const handleDimensionsChange = (newDimensions: Dimensions) => {
    setDimensions(newDimensions);
  };

  const togglePattern = (patternId: string) => {
    const updatedPatterns = patterns.map(pattern =>
      pattern.id === patternId
        ? { ...pattern, selected: !pattern.selected }
        : pattern
    );
    setPatterns(updatedPatterns);
    
    if (originalImage && displayCanvasRef.current) {
      renderPreview(originalImage, displayCanvasRef.current!, updatedPatterns);
    }
  };

  const toggleInvert = (patternId: string) => {
    const updatedPatterns = patterns.map(pattern =>
      pattern.id === patternId
        ? { ...pattern, inverted: !pattern.inverted }
        : pattern
    );
    setPatterns(updatedPatterns);
    
    if (originalImage && displayCanvasRef.current) {
      renderPreview(originalImage, displayCanvasRef.current!, updatedPatterns);
    }
  };

  const removePattern = (patternId: string) => {
    const updatedPatterns = patterns.filter(pattern => pattern.id !== patternId);
    setPatterns(updatedPatterns);
    
    if (originalImage && displayCanvasRef.current) {
      renderPreview(originalImage, displayCanvasRef.current!, updatedPatterns);
    }
  };

  const handleSearchTextures = () => {
    if (confirm('Proceed to Creative Fabrica?')) {
      window.open('https://www.creativefabrica.com/product/distressed-textures/ref/10254595/?campaign=DistressUpdate', '_blank');
    }
  };

  const handleClearDesign = () => {
    setImage(null);
    setOriginalImage(null);
    setOriginalFilename('');
    if (displayCanvasRef.current) {
      const ctx = displayCanvasRef.current.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, displayCanvasRef.current.width, displayCanvasRef.current.height);
      }
    }
  };

  if (showInstructions) {
    return <Instructions onClose={() => setShowInstructions(false)} />;
  }

  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white p-4 sm:p-8">
      <div className="max-w-6xl mx-auto">
        <AdPlaceholder id="1" className="mb-4" />
        <div className="bg-[#1A1A1A] rounded-2xl shadow-2xl shadow-purple-500/10 p-4 sm:p-8 border border-purple-500/10">
          <Header onShowInstructions={() => setShowInstructions(true)} />
          
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="lg:col-span-4">
              <SizeSelector
                presetSizes={presetSizes}
                currentDimensions={dimensions}
                aspectLocked={aspectLocked}
                onSizeSelect={handleSizeSelect}
                onDimensionsChange={handleDimensionsChange}
                onAspectLockToggle={() => setAspectLocked(!aspectLocked)}
              />
              <AdPlaceholder id="2" className="mt-4 hidden lg:block" />
            </div>

            <div className="lg:col-span-8 space-y-6">
              <ActionButtons
                onImageUpload={handleImageUpload}
                onSearchTextures={handleSearchTextures}
                onPatternUpload={handlePatternUpload}
              />

              <PatternSelector
                patterns={patterns}
                onTogglePattern={togglePattern}
                onRemovePattern={removePattern}
                onToggleInvert={toggleInvert}
              />

              <PreviewCanvas
                displayCanvasRef={displayCanvasRef}
                canvasRef={canvasRef}
                hasImage={!!image}
              />

              {image && (
                <ResultActions
                  onDownload={handleDownload}
                  onClear={handleClearDesign}
                />
              )}
              
              <AdPlaceholder id="3" />
            </div>
          </div>
        </div>
        <AdPlaceholder id="4" className="mt-4" />
      </div>
    </div>
  );
};

export default ImageProcessor;