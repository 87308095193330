import React from 'react';

interface PreviewCanvasProps {
  displayCanvasRef: React.RefObject<HTMLCanvasElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  hasImage: boolean;
}

const PreviewCanvas: React.FC<PreviewCanvasProps> = ({
  displayCanvasRef,
  canvasRef,
  hasImage
}) => {
  return (
    <div className="flex justify-center">
      <div className="relative bg-black p-8 rounded-lg w-full max-w-2xl aspect-[3/4]">
        <canvas
          ref={displayCanvasRef}
          className="w-full h-full border-2 border-white/10 rounded-lg"
          width={600}
          height={800}
        />
        <canvas
          ref={canvasRef}
          className="hidden"
        />
        {!hasImage && (
          <div className="absolute inset-0 flex items-center justify-center text-gray-400">
            Design Preview
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewCanvas;