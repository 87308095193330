export const convertToTransparentPNG = async (imageData: string): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      
      if (ctx) {
        // Draw white background first (for PNG transparency)
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        // Draw the image on top
        ctx.drawImage(img, 0, 0);
        
        // Get the image data
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        
        // Convert to grayscale
        for (let i = 0; i < data.length; i += 4) {
          const brightness = (data[i] + data[i + 1] + data[i + 2]) / 3;
          data[i] = brightness;     // R
          data[i + 1] = brightness; // G
          data[i + 2] = brightness; // B
          data[i + 3] = 255;       // A (fully opaque)
        }
        
        // Put the modified image data back
        ctx.putImageData(imageData, 0, 0);
        
        // Convert to JPEG (which doesn't support transparency)
        resolve(canvas.toDataURL('image/jpeg', 0.95));
      } else {
        resolve(imageData); // Fallback to original if context not available
      }
    };
    img.src = imageData;
  });
};