import { DistressPattern } from '../types';

export const createCheckerboardPattern = (ctx: CanvasRenderingContext2D) => {
  const patternCanvas = document.createElement('canvas');
  const patternCtx = patternCanvas.getContext('2d');
  if (!patternCtx) return null;

  patternCanvas.width = 40;
  patternCanvas.height = 40;
  patternCtx.fillStyle = '#0a0a0a';
  patternCtx.fillRect(0, 0, 20, 20);
  patternCtx.fillRect(20, 20, 20, 20);
  patternCtx.fillStyle = '#1a1a1a';
  patternCtx.fillRect(0, 20, 20, 20);
  patternCtx.fillRect(20, 0, 20, 20);

  return ctx.createPattern(patternCanvas, 'repeat');
};

const applyPatternToCanvas = async (
  ctx: CanvasRenderingContext2D,
  pattern: DistressPattern,
  width: number,
  height: number,
  isPreview: boolean
): Promise<void> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      if (!pattern.selected) {
        resolve();
        return;
      }

      // Create a temporary canvas for the pattern
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = width;
      tempCanvas.height = height;
      const tempCtx = tempCanvas.getContext('2d', { willReadFrequently: true });
      
      if (tempCtx) {
        // Scale pattern to fit the canvas
        const scaleX = width / img.width;
        const scaleY = height / img.height;
        
        tempCtx.save();
        tempCtx.scale(scaleX, scaleY);
        tempCtx.drawImage(img, 0, 0);
        tempCtx.restore();

        // Get image data
        const designData = ctx.getImageData(0, 0, width, height);
        const textureData = tempCtx.getImageData(0, 0, width, height);
        const finalData = ctx.createImageData(width, height);

        // Process each pixel
        for (let i = 0; i < designData.data.length; i += 4) {
          const designAlpha = designData.data[i + 3];
          
          // Only process pixels where the design exists
          if (designAlpha > 0) {
            // Get texture pixel values
            const r = textureData.data[i];
            const g = textureData.data[i + 1];
            const b = textureData.data[i + 2];
            
            // Calculate texture intensity (0-255)
            let intensity = Math.round((r + g + b) / 3);
            
            // Invert if needed
            if (pattern.inverted) {
              intensity = 255 - intensity;
            }

            // Calculate final alpha
            const finalAlpha = Math.round(designAlpha * (intensity / 255));

            // Copy original color but modify alpha
            finalData.data[i] = designData.data[i];
            finalData.data[i + 1] = designData.data[i + 1];
            finalData.data[i + 2] = designData.data[i + 2];
            finalData.data[i + 3] = finalAlpha;
          } else {
            // Keep transparent pixels transparent
            finalData.data[i + 3] = 0;
          }
        }

        // Apply the result
        ctx.putImageData(finalData, 0, 0);
      }
      resolve();
    };
    img.src = pattern.imageData;
  });
};

export const renderPreview = async (
  sourceImage: HTMLImageElement,
  canvas: HTMLCanvasElement,
  patterns: DistressPattern[]
) => {
  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  if (!ctx) return;

  const width = canvas.width;
  const height = canvas.height;

  // Calculate scaling to fit the image in the preview while maintaining aspect ratio
  const scale = Math.min(
    width / sourceImage.naturalWidth,
    height / sourceImage.naturalHeight
  );
  
  const scaledWidth = sourceImage.naturalWidth * scale;
  const scaledHeight = sourceImage.naturalHeight * scale;
  
  // Calculate centering offsets
  const x = (width - scaledWidth) / 2;
  const y = (height - scaledHeight) / 2;

  // Draw checkerboard background
  ctx.clearRect(0, 0, width, height);
  const checkerPattern = createCheckerboardPattern(ctx);
  if (checkerPattern) {
    ctx.fillStyle = checkerPattern;
    ctx.fillRect(0, 0, width, height);
  }

  // Create a temporary canvas for processing
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = scaledWidth;
  tempCanvas.height = scaledHeight;
  const tempCtx = tempCanvas.getContext('2d', { willReadFrequently: true });

  if (tempCtx) {
    // Draw scaled image
    tempCtx.drawImage(sourceImage, 0, 0, scaledWidth, scaledHeight);

    // Apply patterns
    for (const pattern of patterns.filter(p => p.selected)) {
      await applyPatternToCanvas(tempCtx, pattern, scaledWidth, scaledHeight, true);
    }

    // Draw the processed image centered on the main canvas
    ctx.drawImage(tempCanvas, x, y);
  }
};

export const processFinalOutput = async (
  sourceImage: HTMLImageElement,
  canvas: HTMLCanvasElement,
  patterns: DistressPattern[],
  dimensions: { width: number; height: number }
) => {
  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  if (!ctx) return;

  canvas.width = dimensions.width;
  canvas.height = dimensions.height;

  // Clear canvas with transparency
  ctx.clearRect(0, 0, dimensions.width, dimensions.height);

  // Draw resized image
  ctx.drawImage(sourceImage, 0, 0, dimensions.width, dimensions.height);

  // Apply each selected pattern
  for (const pattern of patterns.filter(p => p.selected)) {
    await applyPatternToCanvas(ctx, pattern, dimensions.width, dimensions.height, false);
  }
};