import React from 'react';
import { PresetSize, Dimensions, Unit } from '../types';
import { convertToPixels, convertFromPixels } from '../utils/unitConversion';
import { isValidDimension, sanitizeDimensionInput } from '../utils/unitValidation';

interface SizeSelectorProps {
  presetSizes: PresetSize[];
  currentDimensions: Dimensions;
  aspectLocked: boolean;
  onSizeSelect: (size: PresetSize) => void;
  onDimensionsChange: (dimensions: Dimensions) => void;
  onAspectLockToggle: () => void;
}

const SizeSelector: React.FC<SizeSelectorProps> = ({
  presetSizes,
  currentDimensions,
  aspectLocked,
  onSizeSelect,
  onDimensionsChange,
  onAspectLockToggle
}) => {
  const [unit, setUnit] = React.useState<Unit>('px');
  const [error, setError] = React.useState<string | null>(null);

  const handleDimensionChange = (dimension: 'width' | 'height', value: string) => {
    const numValue = sanitizeDimensionInput(value);
    
    if (!isValidDimension(numValue, unit)) {
      setError(`Invalid ${dimension} value for ${unit}`);
      return;
    }
    
    setError(null);
    const pixelValue = convertToPixels(numValue, unit);

    if (aspectLocked) {
      const aspect = currentDimensions.width / currentDimensions.height;
      if (dimension === 'width') {
        onDimensionsChange({
          width: pixelValue,
          height: Math.round(pixelValue / aspect)
        });
      } else {
        onDimensionsChange({
          width: Math.round(pixelValue * aspect),
          height: pixelValue
        });
      }
    } else {
      onDimensionsChange({
        ...currentDimensions,
        [dimension]: pixelValue
      });
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-semibold text-white mb-2">Design Dimensions</h2>
        <p className="text-sm text-gray-400">Set your design dimensions in your preferred unit</p>
      </div>

      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="aspectLock"
            checked={aspectLocked}
            onChange={onAspectLockToggle}
            className="h-4 w-4 bg-[#2A2A2A] border-purple-500/20 text-purple-600 rounded focus:ring-purple-500"
          />
          <label htmlFor="aspectLock" className="text-sm text-gray-300">
            Lock Aspect Ratio
          </label>
        </div>

        <div className="space-y-1">
          <label className="block text-sm font-medium text-gray-300">Unit</label>
          <select
            value={unit}
            onChange={(e) => setUnit(e.target.value as Unit)}
            className="w-full px-2 py-1 bg-[#2A2A2A] border border-purple-500/20 rounded-md text-sm text-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            <option value="px">Pixels (px)</option>
            <option value="cm">Centimeters (cm)</option>
            <option value="mm">Millimeters (mm)</option>
            <option value="in">Inches (in)</option>
          </select>
        </div>
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-300">Apply Custom Dimensions</label>
        <div className="flex items-center space-x-2">
          <div className="flex-1">
            <input
              type="number"
              value={convertFromPixels(currentDimensions.width, unit)}
              onChange={(e) => handleDimensionChange('width', e.target.value)}
              className="w-full px-3 py-2 bg-[#2A2A2A] border border-purple-500/20 rounded-md text-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
              min="0.1"
              step="0.1"
              placeholder="Width"
            />
            <span className="text-xs text-gray-500 mt-1 block">Width ({unit})</span>
          </div>
          <span className="text-gray-400">×</span>
          <div className="flex-1">
            <input
              type="number"
              value={convertFromPixels(currentDimensions.height, unit)}
              onChange={(e) => handleDimensionChange('height', e.target.value)}
              className="w-full px-3 py-2 bg-[#2A2A2A] border border-purple-500/20 rounded-md text-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
              min="0.1"
              step="0.1"
              placeholder="Height"
            />
            <span className="text-xs text-gray-500 mt-1 block">Height ({unit})</span>
          </div>
        </div>
        {error && <p className="text-sm text-red-400 mt-1">{error}</p>}
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-300">Popular Sizes</label>
        <div className="grid grid-cols-1 gap-2">
          {presetSizes.map((size) => (
            <div
              key={size.id}
              className="flex items-center space-x-3 p-3 bg-[#2A2A2A] border border-purple-500/20 rounded-lg hover:bg-[#333333] transition-colors cursor-pointer"
              onClick={() => onSizeSelect(size)}
            >
              <input
                type="radio"
                id={size.id}
                name="presetSize"
                checked={
                  currentDimensions.width === size.width &&
                  currentDimensions.height === size.height
                }
                onChange={() => onSizeSelect(size)}
                className="h-4 w-4 bg-[#1A1A1A] border-purple-500/20 text-purple-600 focus:ring-purple-500"
              />
              <label htmlFor={size.id} className="flex-1 flex items-center justify-between cursor-pointer">
                <span className="text-sm font-medium text-gray-300">{size.name}</span>
                <span className="text-sm text-gray-400">
                  {convertFromPixels(size.width, unit)} {unit} × {convertFromPixels(size.height, unit)} {unit}
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SizeSelector;