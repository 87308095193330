import React from 'react';

interface AdPlaceholderProps {
  id: string;
  className?: string;
}

const AdPlaceholder: React.FC<AdPlaceholderProps> = ({ id, className = '' }) => {
  return (
    <div
      id={`ezoic-pub-ad-placeholder-${id}`}
      className={`min-h-[100px] w-full ${className}`}
    />
  );
};

export default AdPlaceholder;