import React from 'react';
import Logo from './Header/Logo';
import { HelpCircle } from 'lucide-react';

interface HeaderProps {
  onShowInstructions: () => void;
}

const Header: React.FC<HeaderProps> = ({ onShowInstructions }) => {
  return (
    <div className="text-center mb-6 sm:mb-8">
      <Logo />
      <div className="space-y-2">
        <p className="text-sm sm:text-base text-gray-400">
          Print on Demand Resizer and Simple Distress Texture Application for T-Shirt Designers
        </p>
        <button
          onClick={onShowInstructions}
          className="text-sm text-purple-400 hover:text-purple-300 flex items-center gap-1 mx-auto transition-colors"
        >
          <HelpCircle size={16} />
          View Instructions
        </button>
      </div>
    </div>
  );
};

export default Header;