import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';

const BookmarkButton = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const hasBookmarked = localStorage.getItem('distressme_bookmarked');
    if (hasBookmarked === 'true') {
      setIsVisible(false);
    }
  }, []);

  const handleBookmark = (e: React.MouseEvent) => {
    e.preventDefault();
    
    try {
      if ('sidebar' in window) {
        window.sidebar.addPanel(document.title, window.location.href, '');
      } else if (window.external && ('AddFavorite' in window.external)) {
        window.external.AddFavorite(window.location.href, document.title);
      } else {
        alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + ' + D to bookmark this page.');
      }
      
      localStorage.setItem('distressme_bookmarked', 'true');
      setIsVisible(false);
    } catch (error) {
      console.error('Failed to add bookmark:', error);
    }
  };

  if (!isVisible) return null;

  return (
    <button
      onClick={handleBookmark}
      className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-gradient-to-br from-yellow-400 to-yellow-500 text-black font-medium rounded-full hover:from-yellow-500 hover:to-yellow-600 transition-all shadow-lg hover:shadow-yellow-500/25 animate-bounce z-50"
    >
      <Star size={18} fill="currentColor" />
      Bookmark Me!
    </button>
  );
};

export default BookmarkButton;