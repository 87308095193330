import React from 'react';
import { Scissors } from 'lucide-react';

const Logo = () => {
  return (
    <div className="flex items-center justify-center gap-3 mb-2">
      <Scissors className="w-6 h-6 sm:w-8 sm:h-8 text-purple-400 transform -rotate-45" />
      <h1 className="text-2xl sm:text-4xl font-bold bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent">
        DistressMe.com
      </h1>
    </div>
  );
};

export default Logo;