import { Unit } from '../types';
import { formatDimensionValue } from './unitValidation';

const DPI = 300; // Standard print resolution

export const convertToPixels = (value: number, fromUnit: Unit): number => {
  switch (fromUnit) {
    case 'px':
      return Math.round(value);
    case 'in':
      return Math.round(value * DPI);
    case 'cm':
      return Math.round((value / 2.54) * DPI);
    case 'mm':
      return Math.round((value / 25.4) * DPI);
    default:
      return Math.round(value);
  }
};

export const convertFromPixels = (pixels: number, toUnit: Unit): string => {
  let value: number;
  
  switch (toUnit) {
    case 'px':
      value = pixels;
      break;
    case 'in':
      value = pixels / DPI;
      break;
    case 'cm':
      value = (pixels / DPI) * 2.54;
      break;
    case 'mm':
      value = (pixels / DPI) * 25.4;
      break;
    default:
      value = pixels;
  }

  return formatDimensionValue(value, toUnit);
};