import { Unit } from '../types';

export const isValidDimension = (value: number, unit: Unit): boolean => {
  const minValues: Record<Unit, number> = {
    px: 1,
    cm: 0.1,
    mm: 1,
    in: 0.1
  };

  return value >= minValues[unit];
};

export const formatDimensionValue = (value: number, unit: Unit): string => {
  const precision: Record<Unit, number> = {
    px: 0,
    cm: 2,
    mm: 1,
    in: 2
  };

  return value.toFixed(precision[unit]);
};

export const sanitizeDimensionInput = (value: string): number => {
  const parsed = parseFloat(value);
  return isNaN(parsed) ? 0 : Math.max(0, parsed);
};