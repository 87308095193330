import React from 'react';
import { Download, Trash2 } from 'lucide-react';

interface ResultActionsProps {
  onDownload: () => void;
  onClear: () => void;
}

const ResultActions: React.FC<ResultActionsProps> = ({ onDownload, onClear }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-center gap-4">
      <button
        onClick={onDownload}
        className="flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-br from-green-500 to-green-600 text-white rounded-lg hover:from-green-600 hover:to-green-700 transition-all shadow-lg shadow-green-500/20 hover:shadow-green-500/30"
      >
        <Download size={20} />
        Download Design
      </button>
      <button
        onClick={onClear}
        className="flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-br from-red-500 to-red-600 text-white rounded-lg hover:from-red-600 hover:to-red-700 transition-all shadow-lg shadow-red-500/20 hover:shadow-red-500/30"
      >
        <Trash2 size={20} />
        Clear Design
      </button>
    </div>
  );
};

export default ResultActions;