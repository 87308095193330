import React from 'react';
import { ArrowLeft } from 'lucide-react';

interface InstructionsProps {
  onClose: () => void;
}

const Instructions: React.FC<InstructionsProps> = ({ onClose }) => {
  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-[#1A1A1A] rounded-2xl shadow-2xl shadow-purple-500/10 p-6 sm:p-8 border border-purple-500/10">
          <button
            onClick={onClose}
            className="flex items-center gap-2 text-gray-400 hover:text-purple-400 mb-6 transition-colors"
          >
            <ArrowLeft size={20} />
            Back to Editor
          </button>

          <h1 className="text-3xl font-bold bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent mb-8">
            How to Use DistressMe.com
          </h1>

          <div className="space-y-8 text-gray-300">
            <section>
              <h2 className="text-xl font-semibold text-white mb-4">Getting Started</h2>
              <ol className="list-decimal list-inside space-y-2">
                <li>Click "Upload Your Design" to import your artwork (supports PNG, JPG, etc.)</li>
                <li>Your design will appear in the preview window</li>
                <li>Choose your desired output dimensions using the size controls</li>
              </ol>
              <div className="mt-4 p-4 bg-purple-500/10 rounded-lg">
                <p className="text-purple-300 font-medium">💡 Pro Tip:</p>
                <p className="mt-1">For best results, upload high-quality vector designs converted to PNG format. Vector designs ensure crisp, clean edges and maintain quality at any size.</p>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">Size Controls</h2>
              <ul className="list-disc list-inside space-y-2">
                <li><strong>Popular Sizes:</strong> Pre-configured dimensions for common POD products</li>
                <li><strong>Custom Dimensions:</strong> Set exact dimensions in pixels, inches, cm, or mm</li>
                <li><strong>Lock Aspect Ratio:</strong> Maintain proportions when resizing</li>
                <li><strong>Unit Selection:</strong> Choose your preferred measurement unit</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">Adding Distress Effects</h2>
              <ol className="list-decimal list-inside space-y-2">
                <li>Click "Get Textures" to browse Creative Fabrica's collection of distress textures</li>
                <li>Create a Creative Fabrica account if you don't have one</li>
                <li>Download your chosen textures (JPG and PNG formats recommended)</li>
                <li>Click "Upload Your Texture" to import your downloaded texture</li>
                <li>Enable the texture by checking its checkbox</li>
                <li>Use the "Invert" option to reverse the texture effect if needed</li>
                <li>Add multiple textures for complex effects</li>
              </ol>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">Finalizing Your Design</h2>
              <ul className="list-disc list-inside space-y-2">
                <li>Preview your design with applied textures in real-time</li>
                <li>Click "Download Design" to save your finished artwork</li>
                <li>The downloaded file will be a transparent PNG ready for POD platforms</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">Tips & Best Practices</h2>
              <ul className="list-disc list-inside space-y-2">
                <li>Always use high-quality vector designs converted to PNG format</li>
                <li>Vector designs scale perfectly and maintain sharp edges at any size</li>
                <li>JPG and PNG textures from Creative Fabrica work best for distressing</li>
                <li>Experiment with texture inversion for different effects</li>
                <li>Layer multiple textures for unique results</li>
                <li>Always check the preview before downloading</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructions;