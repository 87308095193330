import React from 'react';
import { Trash2, FlipHorizontal } from 'lucide-react';
import { DistressPattern } from '../types';

interface PatternSelectorProps {
  patterns: DistressPattern[];
  onTogglePattern: (id: string) => void;
  onRemovePattern: (id: string) => void;
  onToggleInvert: (id: string) => void;
}

const PatternSelector: React.FC<PatternSelectorProps> = ({
  patterns,
  onTogglePattern,
  onRemovePattern,
  onToggleInvert
}) => {
  if (patterns.length === 0) return null;

  const builtinPatterns = patterns.filter(p => p.builtin);
  const customPatterns = patterns.filter(p => !p.builtin);

  return (
    <div className="space-y-4">
      {builtinPatterns.length > 0 && (
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Built-in Patterns:
          </label>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {builtinPatterns.map(pattern => (
              <div
                key={pattern.id}
                className="flex items-center justify-between p-3 bg-[#2A2A2A] border border-purple-500/20 rounded-lg hover:bg-[#333333] transition-colors"
              >
                <div className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    checked={pattern.selected}
                    onChange={() => onTogglePattern(pattern.id)}
                    className="h-4 w-4 bg-[#1A1A1A] border-purple-500/20 text-purple-600 rounded focus:ring-purple-500"
                  />
                  <img 
                    src={pattern.imageData} 
                    alt={pattern.name}
                    className="h-8 w-8 object-cover rounded bg-[#1A1A1A] border border-purple-500/20"
                  />
                  <span className="text-sm text-gray-300 break-all">{pattern.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {customPatterns.length > 0 && (
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-300">
            Custom Patterns:
          </label>
          <div className="grid grid-cols-1 gap-3">
            {customPatterns.map(pattern => (
              <div
                key={pattern.id}
                className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-3 bg-[#2A2A2A] border border-purple-500/20 rounded-lg hover:bg-[#333333] transition-colors gap-3 sm:gap-0"
              >
                <div className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    checked={pattern.selected}
                    onChange={() => onTogglePattern(pattern.id)}
                    className="h-4 w-4 bg-[#1A1A1A] border-purple-500/20 text-purple-600 rounded focus:ring-purple-500"
                  />
                  <img 
                    src={pattern.imageData} 
                    alt={pattern.name}
                    className="h-8 w-8 object-cover rounded bg-[#1A1A1A] border border-purple-500/20"
                  />
                  <span className="text-sm text-gray-300 break-all">{pattern.name}</span>
                </div>
                <div className="flex items-center space-x-4 w-full sm:w-auto justify-end">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={pattern.inverted}
                      onChange={() => onToggleInvert(pattern.id)}
                      className="h-4 w-4 bg-[#1A1A1A] border-purple-500/20 text-purple-600 rounded focus:ring-purple-500"
                    />
                    <label className="text-sm text-gray-400 flex items-center gap-1">
                      <FlipHorizontal size={14} />
                      Invert
                    </label>
                  </div>
                  <button
                    onClick={() => onRemovePattern(pattern.id)}
                    className="text-gray-400 hover:text-red-400 p-1 transition-colors"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PatternSelector;