import React from 'react';
import ImageProcessor from './components/ImageProcessor';
import BookmarkButton from './components/BookmarkButton';

function App() {
  return (
    <>
      <BookmarkButton />
      <ImageProcessor />
    </>
  );
}

export default App;